import React from "react";
import { Grid } from "theme-ui";
import Layout from "../components/layout";
import AppButton from "../components/layout/app-button";
import ColumnContainer from "../components/layout/column-container";
import ThemeableHeader from "../components/layout/themeable-header";
import Seo from "../components/seo";

const icons = [
  "report",
  "arrowUp",
  "arrowDown",
  "arrowLeft",
  "arrowRight",
  "cal",
  "chart",
  "filter",
  "mapmarker",
  "mic",
  "minus",
  "plus",
  "report",
  "share",
];

const IndexPage = () => (
  <Layout>
    <Seo title="Components" />
    <ThemeableHeader
      color="purple"
      title={"Components"}
      active="/"
      pageIntroduction=""
    />
    <Grid as="section" columns={1} gap={[4, 5, 6]} sx={{ my: [5, 6] }}>
      <ColumnContainer>
        <Grid columns={2}>
          {icons.map((icon) => (
            <AppButton color="yellow" icon={icon}>
              {icon}
            </AppButton>
          ))}
          <AppButton color="coral" icon="report">
            View Report
          </AppButton>
          <AppButton color="text" icon="share">
            Share Article
          </AppButton>
        </Grid>
      </ColumnContainer>
    </Grid>
  </Layout>
);

export default IndexPage;
